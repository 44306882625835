import React, { Component } from 'react';
import Header from "../common/Header";
import NavBar from "../common/NavBar";
//import Pricing from '../Pricing'
import Footer from "../common/Footer";
import HomeBlogList from '../HomeBlogList'

class HomePage extends Component {
    render() {
        return (
            <div>
                <NavBar />
                <Header/>
                <HomeBlogList />
                <Footer />
            </div>
        );
    }
}

export default HomePage;