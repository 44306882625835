import React, { Component } from 'react'


class Header extends Component {
    render() {
        return (
            <>
                <header className="page-header page-header-dark bg-img-cover overlay overlay-80 header-image z-0">
                    <div className="page-header-content">
                        <div className="container text-center">
                            <div className="row justify-content-center">
                                <div className="col-lg-10 col-md-12 col-sm-12 col-xs-12 col-12 z-1">
                                    <h1 className="page-header-title h1">Hi, I'm Icen.</h1>
                                    <p className="page-header-text mb-0">I help businesses increase their revenue by 25% through strategic web design and by automating the repetitive tasks to make room for growth</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="svg-border-rounded text-light">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 144.54 17.34" preserveAspectRatio="none" fill="currentColor" /><path d="M144.54,17.34H0V0H144.54ZM0,0S32.36,17.34,72.27,17.34,144.54,0,144.54,0" />
                    </div>
                </header>
            </>
        )
    }
}

export default Header