import React, { Component } from 'react'
import axios from 'axios'
import ReactMarkdown from 'react-markdown'

class TermsOfUse extends Component {
    constructor() {
        super()
        this.state= {
            terms: []
        }
    }

    componentDidMount() {
        this.getTerms()
    }

    getTerms() {
        axios
            .get('https://vast-temple-87162.herokuapp.com/api/termsOfUse/2a14f7dc-eb59-4bf4-b68e-da2ff915bf5f/')
            .then(res => {
                this.setState({ terms: res.data })
            })
            .catch(err => {
                console.log(err)
            })
    }
    render() {
        return (
            <>
                <div className="container">
                    <ReactMarkdown children={this.state.terms.content} /><br />
                </div>
            </>
        )
    }
}

export default TermsOfUse