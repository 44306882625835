import React, { Component } from 'react';
import NavBar from "../common/NavBar";
import Footer from "../common/Footer";
import Now from '../Now'

class NowPage extends Component {
    render() {
        return (
            <div>
                <NavBar />
                <Now />
                <Footer />
            </div>
        );
    }
}

export default NowPage;