import React, { Component } from 'react';
import NavBar from "../common/NavBar";
import Footer from "../common/Footer";
import ContactForm from '../ContactForm'
import HeaderContact from '../common/HeaderContact'

class ContactPage extends Component {
    render() {
        return (
            <div>
                <NavBar />
                <HeaderContact />
                <ContactForm />
                <Footer />
            </div>
        );
    }
}

export default ContactPage;