import React, { Component } from 'react';
import NavBar from "../common/NavBar";
import Footer from "../common/Footer";
import Uses from '../Uses'

class UsesPage extends Component {
    render() {
        return (
            <div>
                <NavBar />
                <Uses />
                <Footer />
            </div>
        );
    }
}

export default UsesPage;