import React, { Component } from 'react';
import NavBar from "../common/NavBar";
//import Pricing from '../Pricing'
import Footer from "../common/Footer";
import BlogDetail from '../BlogDetail'


class BlogDetailPage extends Component {
    render() {
        return (
            <>
                <NavBar />
                <BlogDetail />
                <Footer />
            </>
        )
    }
}

export default BlogDetailPage