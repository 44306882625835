import React, { Component } from 'react'
import { Link } from 'react-router-dom'

class NavBar extends Component {
    render() {
        return (
            <>
            <nav className="navbar navbar-marketing navbar-expand-lg bg-transparent navbar-light">
                <div className="container-fluid">
                <nav className="navbar-brand">
                    <Link className='font-weight-bold text-gray-800' to="/">
                        Vicente Garcia-Reyes
                    </Link>
                </nav>
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-menu"><line x1="3" y1="12" x2="21" y2="12"></line><line x1="3" y1="6" x2="21" y2="6"></line><line x1="3" y1="18" x2="21" y2="18"></line></svg></button>
                <div className="collapse navbar-collapse" id="navbarContent">
                    <ul className="navbar-nav ml-auto mr-lg-3">
                    <li className="nav-item">
                            <Link className="nav-link" to="/nownownow">
                                Now
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/blog">
                                Blog
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="/uses">
                                Uses
                            </Link>
                        </li>                                      
                        <li className="nav-item">
                            <Link className="nav-link" to="#">
                                Projects
                            </Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" to="#">
                                Bookmarks
                            </Link>
                        </li>                        
                        <li className="nav-item">
                            <Link className="nav-link" to="/contact">
                                Contact
                            </Link>
                        </li>
                    </ul>
                    <Link className="btn btn-md font-weight-500 ml-lg-2 animated--fade-in" to="https://shop.startbootstrap.com/sb-ui-kit-pro">Buy Me A Coffee<i className="ml-2" data-feather="coffee"></i></Link>
                </div>
                </div>
            </nav>
            </>
        )
    }
}

export default NavBar