import React, { Component } from 'react'
import axios from 'axios'
import ReactMarkdown from 'react-markdown'


class PrivacyPolicy extends Component {
    constructor() {
        super()
        this.state= {
            privacy: []
        }
    }

    componentDidMount() {
        this.getPrivacy()
    }

    getPrivacy() {
        axios
            .get('https://vast-temple-87162.herokuapp.com/api/privacy/737494e7-0354-4f9d-8abd-b89c84c75db8/')
            .then(res => {
                this.setState({ privacy: res.data })
            })
            .catch(err => {
                console.log(err)
            })
    }
    render() {
        return (
            <>
                <div className="container">
                    <ReactMarkdown children={this.state.privacy.content} />
                    <br />
                </div>
            </>
        )
    }
}

export default PrivacyPolicy