import React, { Component } from 'react'
import axios from 'axios'
import ReactMarkdown from 'react-markdown'

class Uses extends Component {
    constructor() {
        super()
        this.state= {
            uses: []
        }
    }

    componentDidMount() {
        this.getUses()
    }

    getUses() {
        axios
            .get('https://vast-temple-87162.herokuapp.com/api/uses/7b048122-f677-4eb4-bf5c-947e1adee0b1/')
            .then(res => {
                this.setState({ uses: res.data })
            })
            .catch(err => {
                console.log(err)
            })
    }
    render() {
        return (
            <>  
                <div className="container">
                    <ReactMarkdown className="text-center" children={this.state.uses.content} /><br />
                </div>
            </>
        )
    }
}

export default Uses