import React, { Component } from 'react';
import NavBar from "../common/NavBar";
import Footer from "../common/Footer";
import TermsOfUse from '../TermsOfUse'

class TermsOfUsePage extends Component {
    render() {
        return (
            <div>
                <NavBar />
                <TermsOfUse />
                <Footer />
            </div>
        );
    }
}

export default TermsOfUsePage;