import React, { Component } from 'react'
import axios from 'axios'
import { Link } from 'react-router-dom'
import ReactMarkdown from 'react-markdown'

const formatDate = datetime =>
	new Date(datetime).toDateString()


class BlogList extends Component {
  constructor(props){
    super(props)
    this.state = {
      posts: []
    }
  }

  componentDidMount() {
    this.getPosts()
  }

  getPosts() {
    axios
      .get('https://vast-temple-87162.herokuapp.com/api/post/?status=Published')
      .then(res => {
        this.setState({ posts: res.data })
      })
      .catch(err => {
        console.log(err)
      })
  }

  render() {
    return (
      <>
        <section className="bg-light py-5">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-10">
                        <h1 className="post-archive-tag" >Recent Articles</h1>
                        {this.state.posts.slice(0, 5).map((post) => (
                          <div key={post.uuid} >
                        <Link className="post-archive-item" to={`/blog/${post.slug}`}>
                            <h5>{ post.title }</h5>
                        </Link>
                        {post.categories.map((category) => (
                        <small className="post-archive-meta-details-name"><Link className="post-archive-meta-details-name text-dark font-weight-bold" to='#'>#{category.slug} </Link></small>
                        ))}                                               
                        <ReactMarkdown children={ post.teaser} /><br />
                        <div className="post-archive-meta"><br />
                            <img className="post-archive-meta-img" src="https://vicentereyes-org.s3.amazonaws.com/media/reddit-profile.png" alt="..."/>
                            <div className="post-archive-meta-details">
                                <div className="post-archive-meta-details-name">Icen Reyes</div>
                                <div className="post-archive-meta-details-date"><b>Published on:</b> <i>{formatDate(post.cannonical_date)}</i> <b>Updated on:</b> <i>{formatDate(post.updated_at)}</i></div>
                            </div>
                        </div>                            
                        <hr className="my-4" />
                        </div>
                        ))}
                        <p><Link to="/blog">Read more</Link></p>
                    </div>
                </div>
            </div>
        </section>
      </>
    )
  }
}

export default BlogList