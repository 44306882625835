import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { useParams, Link } from 'react-router-dom'
import ReactMarkdown from 'react-markdown'


const formatDate = datetime =>
  new Date(datetime).toDateString()

function BlogDetail(props){
    const [post, setPost] = useState({})
    let { slug } = useParams()

    useEffect(() => {
        axios({
            method: 'GET',
            url: `https://vast-temple-87162.herokuapp.com/api/post/${slug}`,
        }).then((res) => {
            setPost(res.data)
        })
    }, [slug])
        
        return (
            <section className="bg-light">
                <img className="img-fluid mx-auto d-block h-50" src={post.image} alt="..." />
                
            <p className="text-center font-italic">First seen on: <a href={post.cannonical_url} target="_blank" rel="noreferrer">{post.cannonical_url} </a>on {formatDate(post.cannonical_date)}</p>
        
            <div className="container pt-5 pb-10">
            
                <div className="row justify-content-center">
                    <div className="col-lg-10 col-xl-8" key={post.uuid}>
                        <div className="single-post">
                            
                            <h1>{post.title}</h1>
                            <p className="lead">{post.teaser}</p>
                            <div className="d-flex align-items-center justify-content-between mb-5">
                                <div className="single-post-meta mr-4">
                                    <img className="single-post-meta-img" src="https://vicentereyes-org.s3.amazonaws.com/media/reddit-profile.png" alt="..." />
                                    <div className="single-post-meta-details">
                                        <div className="single-post-meta-details-name">Icen Reyes</div>
                                        <div className="single-post-meta-details-date">Published on: {formatDate(post.published)}</div>
                                    </div>
                                </div>
                                <div className="single-post-meta-links">
                                    <a href="https://github.com/reyesvicente"><i className="fab fa-github"></i></a>
                                    <a href="https://twitter.com/highcenburg"><i className="fab fa-twitter fa-fw"></i></a>
                                    <a href="https://facebook.com/vagreyess"><i className="fab fa-facebook-f fa-fw"></i></a>
                                    <a href="https://linkedin.com/in/vicentereyes0888"><i className="fab fa-linkedin"></i></a>
                                </div>
                            </div>                        
                            <div className="single-post-text my-5 container">
                                <ReactMarkdown children={post.body} />
                                <div className="single-post-meta-links text-center">
                                    <h5>Share on: </h5>
                                    <a href="https://twitter.com/intent/tweet?text=%s"><i className="fab fa-twitter fa-fw"></i></a>
                                    <a href="https://www.facebook.com/sharer/sharer.php?u=%s"><i className="fab fa-facebook-f fa-fw"></i></a>
                                    <a href="https://www.linkedin.com/shareArticle?mini=true&title=%s&url=%s"><i className="fab fa-linkedin"></i></a>
                                    <a href="https://www.reddit.com/submit?title=%s&url=%s"><i className="fab fa-reddit"></i></a>
                                    <a href="mailto:?subject=%s&body=%s"><i className="fa fa-envelope"></i></a>
                                    <a href="https://t.me/share/url?text=%s&url=%s"><i className="fab fa-telegram"></i></a>
                                    <a href="https://api.whatsapp.com/send?text=%s"><i className="fab fa-whatsapp"></i></a>
                                </div>
                                <hr className="my-5" />
                                <div className="text-center"><Link className="btn btn-md font-weight-500 ml-lg-2 animated--fade-in" to="/blog">Back to Blog List</Link></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="svg-border-rounded text-dark">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 144.54 17.34" preserveAspectRatio="none" fill="currentColor" /><path d="M144.54,17.34H0V0H144.54ZM0,0S32.36,17.34,72.27,17.34,144.54,0,144.54,0" />
            </div>
        </section>        
        )
    }


export default BlogDetail