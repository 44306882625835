import React from "react"
import {BrowserRouter, Route, Switch} from "react-router-dom"
import NowPage from "./pages/NowPage"
import UsesPage from "./pages/UsesPage"
import PrivacyPolicyPage from "./pages/PrivacyPolicyPage"
import TermsOfUsePage from "./pages/TermsOfUsePage"
import BlogListPage from './pages/BlogListPage'
import BlogDetailPage from './pages/BlogDetailPage'
import HomePage from './pages/HomePage'
import ContactPage from './pages/ContactPage'


const Router = () => (
    <BrowserRouter>
        <>
            <Switch>
                <Route exact path='/' component={HomePage} />
                <Route exact path="/blog" component={BlogListPage}/>
                <Route exact path="/blog/:slug/" component={BlogDetailPage} />
                <Route exact path="/privacy-policy/" component={PrivacyPolicyPage} />
                <Route exact path="/terms/" component={TermsOfUsePage} />
                <Route exact path="/nownownow/" component={NowPage} />
                <Route exact path="/uses/" component={UsesPage} />
                <Route exact path="/contact/" component={ContactPage} />
            </Switch>
        </>
    </BrowserRouter>
)

export default Router