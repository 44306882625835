import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios'

const baseURL = `https://vast-temple-87162.herokuapp.com/api/contact/`


class ContactForm extends Component {
    constructor(props) {
        super(props)
        this.handleChange = this.handleChange.bind(this)
        this.state = {
            name: '',
            email: '',
            message: '',
            budget: [],
            timeline: '',
            service: ''
        }

        this.handleSubmit = this.handleSubmit.bind(this)
    }

    handleChange(event) {
        this.setState({ 
            name: event.target.value,
            email: event.target.value,
            message: event.target.value,
            budget: event.target.value,
            timeline: event.target.value,
            service: event.target.value,
         })
    }

    handleSubmit(e) {
        e.preventDefault()

        axios({
            method: 'POST',
            url: baseURL,
            data: {
                name: this.state.name.value,
                email: this.state.email.value,
                message: this.state.message.value,
                budget: this.state.budget.value,
                timeline: this.state.timeline.value,
                service: this.state.service.value,
            },
            headers: {
                "Content-Type": "multipart/form-data",
                Accept: 'application/json',
            },
        }).then(res => {
            console.log(res.data)
        }).catch(e => {
            console.log(e)
        })
    }

    render() {
        return (
            <>     
            
                <div className="col-lg-4 px-5 my-auto py-5 mx-5 justify-content-center align-items-center items-center">              
                    <form className='mb-5 justify-content-center align-items-center items-center' onSubmit={this.handleSubmit}>
                        <div className="mb-4">
                            <div className="form-group">
                                <label className="text-dark mb-2" for="name">Full name</label>
                                    <input className="form-control py-3" id="name" type="text" name="name" placeholder="Full name" value={this.state.name.value} onChange={this.handleChange} />
                                </div>
                            <div class="form-group">
                                <label className="text-dark mb-2" for="email">Email</label>
                                    <input id="email" className="form-control py-3" type="email" name="email" placeholder="email@example.com" value={this.state.email.value} onChange={this.handleChange} />
                            </div>
                        </div> 
                        <div className='form-group'>
                            <div class="form-group">
                                <label className='text-dark mb-2' for='message'>Message</label>
                                
                                <textarea className='textarea form-control py-3 ' id='message' type='text' placeholder='Enter your message...' rows='4' value={this.state.email.value} onChange={this.handleChange} />
                            </div>
                        </div>
                        <div className="form-group py-3">
                            <Link className="disabled btn btn-md font-weight-500 animated--fade-in" to="/contact">Inquire!!</Link>
                        </div>
                    </form>
                </div>
            </>
        )
    }
}

export default ContactForm