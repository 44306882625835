import React, { Component } from 'react'
import axios from 'axios'
import ReactMarkdown from 'react-markdown'


class Now extends Component {
    constructor() {
        super()
        this.state= {
            now: []
        }
    }

    componentDidMount() {
        this.getNow()
    }

    getNow() {
        axios
            .get('https://vast-temple-87162.herokuapp.com/api/now/c92af307-c0b1-4f12-a309-38dad82438e0/')
            .then(res => {
                this.setState({ now: res.data })
            })
            .catch(err => {
                console.log(err)
            })
    }
    render() {
        return (
            <>
                <div className="container">
                    <ReactMarkdown children={this.state.now.content} /><br />
                </div>
                
            </>
        )
    }
}

export default Now