import React, { Component } from 'react'

class Footer extends Component {
    render() {
        return (
            <>
                <div id="layoutDefault_footer">
                    <footer className="footer pt-10 pb-5 mt-auto bg-dark footer-dark">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-3">
                                    <div className="footer-brand">Vicente Garcia-Reyes</div>
                                    <div className="mb-3">Iterate, until you can no longer remove an object.</div>
                                    <div className="icon-list-social mb-5">
                                        <a className="icon-list-social-link" href="https://linkedin.com/in/vicentereyes0888">
                                            <i className="fab fa-linkedin-in"></i>
                                        </a>
                                        <a className="icon-list-social-link" href="https://github.com/reyesvicente">
                                            <i className="fab fa-github"></i>
                                        </a>
                                        <a className="icon-list-social-link" href="https://twitter.com/highcenburg">
                                            <i className="fab fa-dev"></i>
                                        </a>
                                        <a className="icon-list-social-link" href="https://dev.to/highcenburg">
                                            <i className="fab fa-twitter"></i>
                                        </a>
                                        <a className="icon-list-social-link" href="https://facebook.com/vagreyess">
                                            <i className="fab fa-facebook"></i>
                                        </a>
                                    </div>
                                </div>
                                <div className="col-lg-9">
                                    <div className="row">
                                        <div className="col-lg-3 col-md-6 mb-5 mb-lg-0 d-none">
                                            <div className="text-uppercase-expanded text-xs mb-4">Product</div>
                                            <ul className="list-unstyled mb-0">
                                                <li className="mb-2"><a href="#!">Landing</a></li>
                                                <li className="mb-2"><a href="#!">Landing</a></li>
                                                <li className="mb-2"><a href="#!">Landing</a></li>
                                                <li className="mb-2"><a href="#!">Landing</a></li>
                                                <li className="mb-2"><a href="#!">Landing</a></li>
                                            </ul>
                                        </div>
                                        <div className="col-lg-3 col-md-6 mb-5 mb-lg-0 d-none">
                                            <div className="text-uppercase-expanded text-xs mb-4">Technical</div>
                                            <ul className="list-unstyled mb-0">
                                                <li className="mb-2"><a href="#!">Landing</a></li>
                                                <li className="mb-2"><a href="#!">Landing</a></li>
                                                <li className="mb-2"><a href="#!">Landing</a></li>
                                                <li className="mb-2"><a href="#!">Landing</a></li>
                                                <li className="mb-2"><a href="#!">Landing</a></li>
                                            </ul>
                                        </div>
                                        <div className="col-lg-3 col-md-6 mb-5 mb-md-0 d-none">
                                            <div className="text-uppercase-expanded text-xs mb-4">Technical</div>
                                            <ul className="list-unstyled mb-0">
                                                <li className="mb-2"><a href="#!">Landing</a></li>
                                                <li className="mb-2"><a href="#!">Landing</a></li>
                                                <li className="mb-2"><a href="#!">Landing</a></li>
                                                <li className="mb-2"><a href="#!">Landing</a></li>
                                                <li className="mb-2"><a href="#!">Landing</a></li>
                                            </ul>
                                        </div>
                                        <div className="col-lg-3 col-md-6 d-none">
                                            <div className="text-uppercase-expanded text-xs mb-4">Legal</div>
                                            <ul className="list-unstyled mb-0">
                                                <li className="mb-2"><a href="#!">Landing</a></li>
                                                <li className="mb-2"><a href="#!">Landing</a></li>
                                                <li className="mb-2"><a href="#!">Landing</a></li>
                                                <li className="mb-2"><a href="#!">Landing</a></li>
                                                <li className="mb-2"><a href="#!">Landing</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr className="my-5" />
                            <div className="row align-items-center">
                                <div className="col-md-6 small">Copyright &copy; Vicente Garcia-Reyes</div>
                                <div className="col-md-6 text-md-right small">
                                    <a href="/privacy-policy/">Privacy Policy</a>
                                    &middot;
                                    <a href="/terms/">Terms &amp; Conditions</a>
                                </div>
                            </div>
                        </div>
                    </footer>
                </div>

            </>
        )
    }
}

export default Footer